@import '@/styles/utils/mixins/breakpoints';

.layoutModeFirstEntryFeatured {
  &.countOneGame {
    --pbsk-component-char-feat-collage--grid-gap: 0;
    --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 1.5);

    --pbsk-component-char-feat-collage--games-list-item-max-width: 90%;
    --pbsk-component-char-feat-collage--games-list-item-margin: 0;

    --pbsk-component-char-feat-collage--character-width: 45%;
    --pbsk-component-char-feat-collage--character-overlap: 10%;

    @include breakpoint(sm) {
      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 2.5);

      --pbsk-component-char-feat-collage--games-list-item-max-width: 85%;

      --pbsk-component-char-feat-collage--character-width: 39%;
      --pbsk-component-char-feat-collage--character-overlap: 4%;
    }

    @include breakpoint(md) {
      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 2.5);

      --pbsk-component-char-feat-collage--games-list-item-max-width: 69%;
      --pbsk-component-char-feat-collage--games-list-item-margin: 0 0 0 auto;

      --pbsk-component-char-feat-collage--character-overlap: -14%;
      --pbsk-component-char-feat-collage--character-width: calc(
        2% + 100% - var(--pbsk-component-char-feat-collage--games-list-item-max-width)
      );

      .gamesListItem,
      .characterImage {
        right: 8.5%;
      }

      .characterImage {
        text-align: left;
        max-width: 328px;

        img { width: 100%; }
      }
    }

    @include breakpoint(lg) {
      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 2);

      --pbsk-component-char-feat-collage--games-list-item-max-width: 75.25%;

      --pbsk-component-char-feat-collage--character-overlap: -2%;
      --pbsk-component-char-feat-collage--character-width: calc(
        10% + 100% - var(--pbsk-component-char-feat-collage--games-list-item-max-width)
      );


      .gamesListItem,
      .characterImage {
        right: 10%;
      }
    }

    @include breakpoint(xl) {
      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 1.5);
    }

    .characterImage img {
      // This is a safeguard to ensure the image
      // doesn't pop too high out of the module.
      // This was happening at some oddball screen sizes.
      @include between-breakpoints(md, lg) {
        max-width: 28vw !important;
      }
    }
  }

  &.countThreeGame {
    @include between-breakpoints(null, sm) {
      overflow: hidden;

      --pbsk-component-char-feat-collage--grid-cols: 100%;
      --pbsk-component-char-feat-collage--grid-gap: calc(var(--pbsk-component-char-feat-collage--base-padding) * .75) 0;
      --pbsk-component-char-feat-collage--grid-padding-right: var(--pbsk-component-char-feat-collage--base-padding);

      --pbsk-component-char-feat-collage--character-width: 55%;

      --pbsk-component-char-feat-collage--games-list-item-margin: 0;
      --pbsk-component-char-feat-collage--games-list-item-max-width: 50%;

      .characterImage {
        right: calc(-.5 * var(--pbsk-component-char-feat-collage--base-padding));
      }

      .gamesListItem {
        &:nth-child(1) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;
          --pbsk-component-char-feat-collage--games-list-item-max-width: 100%;
        }

        &:nth-child(2) {
          grid-column: 1 / span 1;
          grid-row: 2 / span 1;
        }

        &:nth-child(3) {
          grid-column: 1 / span 1;
          grid-row: 3 / span 1;
        }
      }
    }

    @include between-breakpoints(sm, lg) {
      --pbsk-component-char-feat-collage--grid-cols: repeat(25%, 4);
      --pbsk-component-char-feat-collage--character-overlap: 4%;
      --pbsk-component-char-feat-collage--character-width: 40%;

      .gamesListItem {
        &:nth-child(1) {
          grid-column: 2 / span 2;
          grid-row: 1 / span 1;
        }

        &:nth-child(2) {
          grid-column: 1 / span 2;
          grid-row: 2 / span 1;
        }

        &:nth-child(3) {
          grid-column: 3 / span 2;
          grid-row: 2 / span 1;
        }
      }
    }

    @include breakpoint(lg) {
      --pbsk-component-char-feat-collage--grid-cols: repeat(25%, 1fr) auto;
      --pbsk-component-char-feat-collage--grid-gap: calc(var(--pbsk-component-char-feat-collage--base-padding) * .75);
      --pbsk-component-char-feat-collage--grid-padding-right: 100%;

      --pbsk-component-char-feat-collage--games-list-item-margin: 0;
      --pbsk-component-char-feat-collage--games-list-item-max-width: none;

      --pbsk-component-char-feat-collage--character-width: 28%;

      .gamesListItem {
        &:nth-child(1) {
          width: 478px;
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;
        }

        &:nth-child(2) {
          width: 222px;
          grid-column: 3 / span 1;
          grid-row: 1 / span 1;
        }

        &:nth-child(3) {
          width: 222px;
          grid-column: 3 / span 1;
          grid-row: 2 / span 1;
        }
      }

      .characterImage > * {
        width: 100%;
      }
    }
  }
}
