@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/shadows';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/functions/rem-calc';

.gameCard {
  position: relative;
}

.staticFlairWrapper {
  position: absolute;
  top: var(--pbsk-spacing-2);
  left: var(--pbsk-spacing-2);
}

.gameContainer {
  position: relative;
  border-radius: var(--pbsk-spacing-5);
  overflow: hidden;

  @include standardTransition($properties: box-shadow);
  @include aspect-ratio(454, 255);

  [data-hover-group='game-card']:hover &,
  [data-hover-group='game-card']:focus-within & {
    @include box-shadow-border(var(--pbsk-component-media-item-hover-border-color));
  }

  a {
    display: block;
  }

  img {
    pointer-events: none;
  }

  &.hasFlair {
    background-color: transparent;

    $radius: var(--pbsk-spacing-6);
    $offset: var(--pbsk-spacing-2);

    img {
      // Feathering for 4% of the radius, otherwise the edge of the circle scoop is pixelated.
      mask-image: radial-gradient(
        circle $radius at $offset $offset,
        transparent 96%,
        black
      );
    }
  }
}
