@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/functions/rem-calc';

.staticFlair {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  width: var(--pbsk-spacing-9);
  height: var(--pbsk-spacing-9);
  background-color: var(--pbsk-theme-calloutBackgroundColor);
  border-radius: 50%;

  @include standardTransition($duration: 150ms);

  [data-hover-group='game-card']:hover & {
    width: var(--pbsk-spacing-12);
    height: var(--pbsk-spacing-12);
  }
}

