@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/focus-visible';
@import '@/styles/utils/mixins/shadows';

.lookInsideCard {
  position: relative;
  border-radius: 15px;
  cursor: pointer;

  @include standardTransition($properties: box-shadow);

  &:hover,
  &:focus-within {
    @include box-shadow-border(var(--pbsk-theme-uiHighlightColor));
  }
}

.activeCard {
  @include box-shadow-border(var(--pbsk-theme-uiHighlightColor));
}

.cardSlides {
  position: relative;

  @include aspect-ratio(454, 255); // Mezzanine aspect ratio
}

.cardSlide {
  position: absolute;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  opacity: 0;

  @include standardTransition($properties: opacity);
  @include aspect-ratio(454, 255);

  img {
    display: block;
    object-fit: cover;
  }
}

.currentSlide {
  opacity: 1;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  max-height: 100px;
  padding: 3.5%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.lookBadge {
  width: 18%;
  min-width: 40px;
}

.playButton {
  position: absolute;
  right: calc(-1 * var(--pbsk-focus-outline-width));
  bottom: calc(-1 * var(--pbsk-focus-outline-width));
  width: calc(35.25% + var(--pbsk-focus-outline-width));
  max-width: 250px;
  background-color: var(--pbsk-theme-uiHighlightColor);
  height: calc(100% + var(--pbsk-focus-outline-width));
  border-radius: 15px 0 25px;
  opacity: 0;

  &:focus {
    outline: none;
  }

  svg {
    display: none;
    width: 60%;
    margin: 0 auto;
    fill: var(--pbsk-component-lookinside-play-textColor, #fff);
    pointer-events: none;
  }
}

.playButtonActive {
  opacity: 1;
  transition-delay: 100ms;

  @include standardTransition($properties: opacity, $duration: 350ms);

  svg {
    display: block;
  }
}

.paginationWrapper {
  width: 7.5%;
  margin-left: -1px;
}
