.paginationWrapper {
  position: relative;
  display: grid;
  height: 0;
  transform: rotate(-90deg);
  transform-origin: top left;
}

.paginationWrapperCentered {
  align-items: center;
  transform-origin: center center;
}

.pagination {
  width: 100%;
  position: absolute;
  padding: 1px;
  background-color: rgb(0 0 0 / 60%);
  border-radius: 32px;
}

.dot {
  display: block;
  height: 1px;
  width: 1px;
  padding: 14%;
  border-radius: 100%;
  border: 1px solid #fff;
  background-color: transparent;
  margin: 45% 0 45% 30%;
}

.currentDot {
  background-color: #fff;
}
