@import '@/styles/utils/mixins/breakpoints';

@mixin x-small-stack($game-count: null) {
  --pbsk-component-char-feat-collage--grid-cols: repeat(50%, 2);
  --pbsk-component-char-feat-collage--grid-gap: calc(var(--pbsk-component-char-feat-collage--base-padding) * .75);

  --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 1.25);

  --pbsk-component-char-feat-collage--character-overlap: 7%;
  --pbsk-component-char-feat-collage--character-width: 59%;

  .gamesListItem {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    &:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }

    &:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }

    &:nth-child(4) {
      grid-column: 2;
      grid-row: 1;
    }
  }

  @if $game-count == 4 {
    --pbsk-component-char-feat-collage--grid-padding-right: var(--pbsk-component-char-feat-collage--base-padding);
  }
}

.layoutModeDefault {
  &.countTwoGame {
    @include between-breakpoints(null, sm) {
      @include x-small-stack;
    }

    @include breakpoint(sm) {
      --pbsk-component-char-feat-collage--character-width: 42%;
      --pbsk-component-char-feat-collage--character-overlap: 11%;

      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 4);
    }

    @include breakpoint(lg) {
      --pbsk-component-char-feat-collage--games-list-item-max-width: 100%;

      --pbsk-component-char-feat-collage--character-width: 34%;
      --pbsk-component-char-feat-collage--character-overlap: 9%;

      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 3);
    }

    .characterImage img {
      // This is a safeguard to ensure the image
      // doesn't pop too high out of the module.
      // This was happening at some oddball screen sizes.
      @include between-breakpoints(sm, md) {
        max-width: 36vw !important;
      }

      @include between-breakpoints(md, lg) {
        max-width: 33vw !important;
      }
    }
  }

  &.countThreeGame {
    @include between-breakpoints(null, sm) {
      @include x-small-stack;
    }

    @include between-breakpoints(sm, lg) {
      --pbsk-component-char-feat-collage--grid-cols: repeat(25%, 4);
      --pbsk-component-char-feat-collage--character-overlap: 4%;
      --pbsk-component-char-feat-collage--character-width: 40%;

      .gamesListItem {
        &:nth-child(1) {
          grid-column: 2 / span 2;
          grid-row: 1 / span 1;
        }

        &:nth-child(2) {
          grid-column: 1 / span 2;
          grid-row: 2 / span 1;
        }

        &:nth-child(3) {
          grid-column: 3 / span 2;
          grid-row: 2 / span 1;
        }
      }
    }

    @include breakpoint(lg) {
      --pbsk-component-char-feat-collage--games-list-item-max-width: 100%;

      --pbsk-component-char-feat-collage--character-width: 25%;
      --pbsk-component-char-feat-collage--character-overlap: 0%;

      --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 3);
    }
  }

  &.countFourGame {
    @include between-breakpoints(null, sm) {
      @include x-small-stack(4);
    }

    @include between-breakpoints(sm, lg) {
      --pbsk-component-char-feat-collage--grid-cols: 50% 50%;
      --pbsk-component-char-feat-collage--character-overlap: 5%;
      --pbsk-component-char-feat-collage--character-width: 44%;
    }

    @include breakpoint(lg) {
      --pbsk-component-char-feat-collage--grid-cols: 20% 20% 20% 20% 20%;
      --pbsk-component-char-feat-collage--grid-padding-right: 48%;

      --pbsk-component-char-feat-collage--character-width: 44%;
      --pbsk-component-char-feat-collage--character-overlap: 0%;

      .gamesListItem {
        &:nth-child(1) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;
        }

        &:nth-child(2) {
          grid-column: 3 / span 2;
          grid-row: 1 / span 1;
        }

        &:nth-child(3) {
          grid-column: 2 / span 2;
          grid-row: 2 / span 1;
        }

        &:nth-child(4) {
          grid-column: 4 / span 2;
          grid-row: 2 / span 1;
        }
      }
    }
  }
}
