@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/dev-ui';
@import './GamesCharacterFeatureCollage.layoutDefault.module';
@import './GamesCharacterFeatureCollage.layoutFirstEntryFeatured.module';

.wrapper {
  --pbsk-component-char-feat-collage--games-list-item-max-width: 344px;
  --pbsk-component-char-feat-collage--games-list-item-margin: 0 auto;

  --pbsk-component-char-feat-collage--character-overlap: 4%;
  --pbsk-component-char-feat-collage--character-width: calc(39% / var(--pbsk-component-char-feat-collage-game-count));

  --pbsk-component-char-feat-collage--base-padding: var(--pbsk-component-themed-module-base-padding);
  --pbsk-component-char-feat-collage--vertical-padding: calc(var(--pbsk-component-char-feat-collage--base-padding) * 1.5);
  --pbsk-component-char-feat-collage--default-grid-cols: repeat(var(--pbsk-component-char-feat-collage-game-count), 1fr) auto;
  --pbsk-component-char-feat-collage--grid-gap: var(--pbsk-component-char-feat-collage--base-padding);
  --pbsk-component-char-feat-collage--grid-padding-right: calc(var(--pbsk-component-char-feat-collage--character-width) - var(--pbsk-component-char-feat-collage--character-overlap));

  position: relative;
  line-height: 0;
}

.theGrid {
  display: grid;
  align-items: center;
  grid-template-columns: var(--pbsk-component-char-feat-collage--grid-cols, var(--pbsk-component-char-feat-collage--default-grid-cols));
  gap: var(--pbsk-component-char-feat-collage--grid-gap);
  padding: var(--pbsk-component-char-feat-collage--vertical-padding) var(--pbsk-component-char-feat-collage--base-padding);
  padding-right: var(--pbsk-component-char-feat-collage--grid-padding-right);
}

.gamesListItem {
  position: relative;
  z-index: 1;

  & > * {
    margin: var(--pbsk-component-char-feat-collage--games-list-item-margin);
    max-width: var(--pbsk-component-char-feat-collage--games-list-item-max-width);
  }
}

.characterImage {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  width: var(--pbsk-component-char-feat-collage--character-width);

  @include breakpoint(lg) {
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
}

// Allows us to override the default padding so content can be flush with the edge of the container
.themedContentWrapper {
  padding: 0;
}

.debugTag {
  @include devToolBase;
  position: absolute;
  top: var(--pbsk-dev-ui-default-padding);
  left: var(--pbsk-dev-ui-default-padding);
  z-index: 2;
  border-radius: var(--pbsk-dev-ui-border-radius);
  background: rgb(var(--pbsk-theme-uiControlColor-rgb) / 90%);
  padding: var(--pbsk-dev-ui-default-padding);
  margin: 0;
  font-size: 8px;
  max-height: 100%;
  overflow: auto;
}
