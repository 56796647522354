@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/dev-ui';

.moduleWrapper {
  position: relative;
  padding: 0 !important;

  --pbsk-component-themed-module-base-padding: var(--pbsk-component-page-section-stack-padding-x, var(--pbsk-spacing-6));

  // Allow themed module content to align on left and right with non-themed module content
  @include breakpoint(xl) {
    [data-component-page-section-stack] & {
      --pbsk-component-themed-module-base-padding: var(--pbsk-spacing-6);
      margin-left: calc(-1 * var(--pbsk-component-themed-module-base-padding));
      margin-right: calc(-1 * var(--pbsk-component-themed-module-base-padding));
    }
  }

  // Design wants themed modules to be full width and have no vertical gaps between them,
  // but the path to full width modules is a little fraught on larger screens. Discussion here:
  //  - https://pbs.slack.com/archives/C04F2F4QLRH/p1729625643191769
  //  - https://www.pivotaltracker.com/story/show/188438412/comments/242860659

  // Remove vertical gaps between themed modules on smaller screens where full width already can happen.
  margin: calc(-.5 * var(--pbsk-component-page-section-stack-module-gap, 0)) 0;

  // !!!
  //  gap for flexbox not supported on iOS 13 and below <https://caniuse.com/flexbox-gap>
  //  On those versions, do not add negative margins to remove vertical gaps.
  //  This `supports` workaround found here: <https://stackoverflow.com/a/77517366>
  // !!!
  @supports not (inset: 0) {
    margin: 0 !important;
  }

  @include breakpoint(lg) {
    // We will circle back to supporting full width themed modules on larger screens later, but until then:
    //  - Separate themed modules by a bit less vertical gaps than the other modules
    //  - Add rounded corners to themed modules

    margin: calc(-.25 * var(--pbsk-component-page-section-stack-module-gap, 0)) 0;

    /* stylelint-disable no-descending-specificity */
    &, .bgLayers > div {
      border-radius: 12px;
    }
  }
}

.innerContent {
  position: relative;
  z-index: 1;

  // Because there is a default color set with less specificity which inherits the page theme,
  // we need to re-set the color here to ensure the text color is correct.
  color: var(--pbsk-theme-bodyTextColor);

  padding: var(--pbsk-component-themed-module-base-padding);
}

.bgLayers {
  position: absolute;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.solidColor {
  background: var(--pbsk-theme-bodyBackgroundColor);
}

.image {
  background-image: var(--pbsk-theme-backgroundImage);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.pattern {
  background-image: var(--pbsk-theme-backgroundPattern);
  background-position: 0 0;
  background-size: var(--pbsk-theme-backgroundPattern-width, auto)
    var(--pbsk-theme-backgroundPattern-height, auto);
}

.themedModuleDebugger {
  @include devToolBase;

  position: absolute;
  z-index: 2;
  top: var(--pbsk-dev-ui-default-padding);
  right: var(--pbsk-dev-ui-default-padding);
  background: rgb(var(--pbsk-theme-uiControlColor-rgb) / 90%);
  border-radius: var(--pbsk-dev-ui-border-radius);
  padding: 2px 6px;
  display: inline-block;

  summary {
    cursor: pointer;
  }

  pre {
    max-height: 300px;
    overflow-y: scroll;
    max-width: calc(100vw - 28px);
  }
}
